<template>
  <div class="mtv-layout">
    <slot />
  </div>
</template>

<style lang="scss">
html {
  scroll-behavior: smooth;
}

@media (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: auto;
  }
}

.no-scroll {
  overflow: hidden;
}
.cf:after {
  content: "";
  display: table;
  clear: both;
}

.mtv-layout {
  font-family: $font-body;
  font-weight: normal;
  height: calc(100% - 50px);
  font-size: 16px;
}
p,
h1,
h2,
h3,
h4,
h5 {
  color: $font-color-default-dark;
  &.light {
    color: $font-color-default-light;
  }
  padding: 0;
  margin: 0;
  line-height: 1;
}
h1,
h2,
h3,
h4,
h5 {
  font-family: $font-headline;
  padding-top: 0.15em;
}
p {
  font-family: $font-body;
}
</style>

<script>
import "@/assets/css/fonts.scss";
export default {};
</script>
