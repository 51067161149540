interface IAddEventListener {
  addEventListener(event: string, handler: (event: any) => any): void;
  removeEventListener(event: string, handler: (event: any) => any): void;
}

/**
 * Creates a listener for visual related tasks. Primarily used with scroll events and resize events,
 * where the browser may many more events than the browser's framerate. To use:
 *
 * const onScroll = createThrottleListener("scroll", window);
 * // Create a listener
 * const destroyOnScroll = onScroll(() => someCallbackFunction());
 *
 * // Later, stop listening for scroll events:
 * destroyOnScroll();
 *
 * @param eventName The name of the event to create a listener for.
 * @param object
 */
export function createThrottleListener(
  eventName: string,
  object: IAddEventListener
) {
  // The function to connect the listener to a callback
  return function(callback: (...args: any[]) => void) {
    let handle: number;
    let debouncing: boolean = false;
    let lastArgs: any[];
    const debounceFunction = () => {
      callback.apply(null, lastArgs);
      debouncing = false;
    };

    // The event listener to listen for.
    const listener = (...args: any[]) => {
      lastArgs = args;
      if (!debouncing) {
        handle = window.requestAnimationFrame(debounceFunction);
        debouncing = true;
      }
    };

    const disconnect: () => void = createListener.call(
      null,
      eventName,
      object,
      listener
    );

    return () => {
      window.cancelAnimationFrame(handle);
      disconnect();
    };
  };
}

/**
 * Connects a function to an event listener and returns a function to stop listening for events
 */
export function createListener(
  eventName: string,
  object: IAddEventListener,
  callback: (...args: any[]) => void
) {
  const listener = (...args: any[]) => callback(...args);
  object.addEventListener(eventName, listener);

  // The handle to disconnect
  return function() {
    object.removeEventListener(eventName, listener);
  };
}
