import { TimelineMax, Back, Power2, RoughEase, TweenMax } from "gsap";
import { JSTransition, JSTransitionCallback } from "./JSTransition";
import { VNode } from "vue";

function killOf(el: any) {
  TweenMax.getTweensOf(el).forEach((t: any) => {
    t.progress(1);
    t.kill();
  });
}

export function flickerTransition(props: any): JSTransition {
  const duration: number = props.duration || 2;
  const scale: number = props.scale || 1;
  const delay: number = props.delay || 0.5;

  return {
    beforeEnter(el: VNode) {
      killOf(el);
      TweenMax.to(el, 0, { opacity: 0 });
    },
    enter(el: VNode, done: JSTransitionCallback) {
      // killOf(el);

      const opacity = TweenMax.fromTo(
        el,
        duration * 0.5,
        {
          opacity: 0
        },
        {
          opacity: 1,
          // @ts-ignore
          ease: RoughEase.ease.config({
            points: 90,
            strength: 5,
            taper: "out",
            clamp: true
          })
        }
      );

      const slide = TweenMax.fromTo(
        el,
        duration,
        { x: "20%", scaleX: scale, scaleY: scale },
        { x: "0%", scaleX: 1, scaleY: 1, ease: Back.easeOut }
      );

      // Run the timeline
      const tl = new TimelineMax();
      tl.add(slide, delay);
      tl.add(opacity, delay);
      tl.eventCallback("onComplete", done);
    },

    leave(el: VNode, done: JSTransitionCallback) {
      killOf(el);
      TweenMax.to(el, 0.3, {
        opacity: 0,
        scaleX: scale,
        scaleY: scale,
        ease: Power2.easeIn,
        onComplete: done
      });
    }
  };
}
