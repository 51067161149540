





































































































































































































































































































































































































import Vue from "vue";
// import FlickerTransition from "@/components/fx/FlickerTransition.vue";

export default Vue.extend({
  props: {
    search: {
      type: Boolean,
      default: false
    }
  },
  components: {
    // FlickerTransition
  },
  methods: {
    onHamburgerClick() {
      this.$store.commit("setNavOpen", true);
    }
  }
});
