





























import Vue from "vue";
import LazyImg from "./LazyImg.vue";
import { ProgressiveImage } from "@/js/data-types";

export default Vue.extend({
  components: {
    LazyImg
  },
  props: {
    // The lquip-loader object passed through wich contains a base64 low quality image, and the
    // full quality url.
    url: {
      type: Object as () => ProgressiveImage,
      required: true
    },
    autoLoad: {
      type: Boolean,
      default: true
    },
    blur: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    onLoaded(): void {
      this.$emit("loaded");
    }
  }
});
