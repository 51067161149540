


















































































































































































































































































































































import Vue from "vue";
export default Vue.extend({
  data() {
    return {
      animating: false
    };
  },
  methods: {
    animateIn(): void {
      this.animating = true;
    }
  }
});
