









import Vue from "vue";
import MTVStyles from "./MTVStyles.vue";
import MTVHeader from "../components/MTVHeader.vue";
import MTVFooter from "../components/MTVFooter.vue";
import MTVNewsFooter from "../components/MTVNewsFooter.vue";
export default Vue.extend({
  components: {
    MTVStyles,
    MTVHeader,
    MTVFooter,
    MTVNewsFooter
  }
});
