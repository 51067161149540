


























import Vue from "vue";
import Caption from "@/components/Caption.vue";
import { Size } from "../js/data-types";

export default Vue.extend({
  components: {
    Caption
  },
  props: {
    head: {
      type: String
    },
    text: {
      type: String
    },
    url: {
      type: String
    },
    size: {
      type: String as () => Size,
      default: Size.standard,
      validator(value: Size) {
        // The size can only be standard or medium
        return value == Size.standard || value == Size.medium;
      }
    }
  }
});
