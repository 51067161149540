"use strict";
// Author: Jeremy Meltingtallow, Keely Honeywell (just some edits for browser compatibility mostly)

export var HorizonParallax = /** @class */ (function() {
  function HorizonParallax() {
    this.elementsArray = [];
    this.scrollY = window.scrollY;
    this.windowHeight = window.innerHeight;
  }

  /**
   * Convinience method to auto initilazes elements by searching the dom for elements with the given class name
   * Defaults to class name "parallax"
   *
   * @param {string} [selector=".parallax"] The class name to search the dom for.
   */
  HorizonParallax.prototype.init = function(selector) {
    if (!selector) {
      selector = ".parallax";
    }
    var parallaxElements = document.querySelectorAll(selector);
    for (var i = 0; i < parallaxElements.length; i++) {
      var el = parallaxElements[i];
      this.add(
        new ParallaxElement(el, el.dataset.distance, el.dataset.horizon)
      );
    }
  };

  /**
   * Adds a parallax element to the class to manage
   * @param {ParallaxElement} parallaxElement The parallax element to add to the manager.
   */
  HorizonParallax.prototype.add = function(parallaxElement) {
    var _this = this;
    this.scrollY = window.scrollY;
    this.elementsArray.push(parallaxElement);
    parallaxElement.resize(_this.windowHeight);
    parallaxElement.setPosition(_this.windowHeight, _this.scrollY);
  };

  /**
   * Removes a parallax element from the HorizonParallax manager
   * @param {ParallaxElement} parallaxElement The parallax element to remove from the manager.
   */
  HorizonParallax.prototype.remove = function(parallaxElement) {
    var id = this.elementsArray.indexOf(parallaxElement);
    if (id >= 0) {
      this.elementsArray.splice(id, 1);
    } else {
      console.log("Cannot find parallaxElement to remove it.");
    }
  };

  HorizonParallax.prototype.scroll = function() {
    this.scrollY = window.scrollY;
    this.setElementPositions();
  };

  HorizonParallax.prototype.resize = function() {
    var _this = this;
    this.windowHeight = window.innerHeight;
    this.elementsArray.forEach(function(element) {
      element.resize(_this.windowHeight);
    });
    this.setElementPositions();
  };

  HorizonParallax.prototype.setElementPositions = function() {
    var _this = this;
    this.elementsArray.forEach(function(element) {
      element.setPosition(_this.windowHeight, _this.scrollY);
    });
  };

  return HorizonParallax;
})();

export var ParallaxElement = /** @class */ (function() {
  /**
   * @param {HTMLElement} elem The element to target
   * @param {(number|string)} distance The distance
   * @param {(number|string)} horizon The horizon line to use
   * @param {funcion} [setter=] setter function to notify of offset changes. by default, sets the translate3d property on the element.
   */
  function ParallaxElement(elem, distance, horizon, setter) {
    var _this = this;
    this.elem = elem;

    this.horizon = typeof horizon == "string" ? parseFloat(horizon) : horizon;
    this.setter =
      typeof setter == "function"
        ? setter
        : function(offset) {
            _this.elem.style.transform = "translate3d(0, " + offset + "px, 0)";
          };

    this.setDist(distance);
    this.computedHorizon = this.horizon;
    this.offsetTop = this.elem.offsetTop;
    this.setter(0);
  }

  /**
   * Setter to change the distance of the instance.
   */
  ParallaxElement.prototype.setDist = function(distance) {
    var dist = typeof distance == "string" ? parseFloat(distance) : distance;
    this.zDistance = 1 / dist;
  };

  /**
   * Method to recalculate whenever the window size changes.
   * @param {number} windowHeight The height of the window
   */
  ParallaxElement.prototype.resize = function(windowHeight) {
    var top = 0;
    var el = this.elem;
    while (el) {
      top += el.offsetTop;
      el = el.offsetParent;
    }
    this.offsetTop = top; //this.elem.offsetTop;
    var bottom = windowHeight - (bodyHeight() - this.offsetTop);
    var horizonVal = windowHeight * this.horizon;
    if (top < horizonVal) {
      this.computedHorizon = top / windowHeight;
    } else if (bottom > 0) {
      this.computedHorizon = bottom / windowHeight;
    }
  };

  /**
   * Sets the position of the element managed by the HorizonParallax class.
   *
   * @param {number} windowHeight The height of the window.
   * @param {number} scrollY The y scroll position of the window.
   */
  ParallaxElement.prototype.setPosition = function(windowHeight, scrollY) {
    var currenPositionWorld = this.offsetTop - scrollY;
    var curDistance = currenPositionWorld - windowHeight * this.computedHorizon;
    this.setter(curDistance * this.zDistance);
  };

  function bodyHeight() {
    return Math.max(document.body.scrollHeight, document.body.offsetHeight);
  }

  return ParallaxElement;
})();

if (typeof module !== "object") {
  // Loaded by including the script on the page.
  window.Parallax = HorizonParallax;
  window.ParallaxElement = ParallaxElement;
}
