import { render, staticRenderFns } from "./MainArticle.vue?vue&type=template&id=01fcc478&scoped=true&"
import script from "./MainArticle.vue?vue&type=script&lang=ts&"
export * from "./MainArticle.vue?vue&type=script&lang=ts&"
import style0 from "./MainArticle.vue?vue&type=style&index=0&id=01fcc478&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01fcc478",
  null
  
)

export default component.exports