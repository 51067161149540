
import Vue, { CreateElement, RenderContext, VNode } from "vue";
// import { TimelineMax, Back, RoughEase } from "gsap";
import { flickerTransition } from "@/js/fx/transitions";

export default Vue.extend({
  functional: true,
  render(
    createElement: CreateElement,
    context: RenderContext<Record<string, any>>
  ): VNode {
    const data: any = {
      props: {
        name: "flicker",
        mode: "out-in"
      },
      on: {
        afterEnter: context.listeners.afterEnter || (() => {}),
        afterLeave: context.listeners.afterLeave || (() => {}),
        ...flickerTransition(context.props)
      }
    };
    // console.log(context);
    return createElement("transition", data, context.children);
  }
});
