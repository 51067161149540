






























































import Vue from "vue";
import { Size } from "@/js/data-types";
export default Vue.extend({
  props: {
    darkText: {
      type: Boolean,
      default: false
    },
    headSize: {
      type: String as () => Size,
      default: Size.standard
    },
    head: {
      type: String
    },
    text: {
      type: String
    },
    url: {
      type: String
    }
  }
});
