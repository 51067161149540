












import Vue from "vue";
import { createThrottleListener } from "@/js/util/Listeners";
import { Rectangle, create as createRect } from "@/js/geom/Rectangle";
import { elementRect, viewportRect, documentRect } from "@/js/util/DOM";
import { percent as percentScrolled } from "@/js/util/Viewport";
import { clamp } from "@/js/math/FMath";

interface Fn {
  (): void;
}

interface Data {
  /** If the component is currently on screen */
  onScreen: boolean;
  /** The percentage scrolled on page */
  progress: number;
}

interface Options {
  observer: IntersectionObserver;
  /** The viewport rectangle, includes scroll position */
  vpRect: Rectangle;
  /** The entire document rectangle */
  docRect: Rectangle;
  /** The bounds of the element */
  elRect: Rectangle;
  /** The function to call to stop listening to scroll events */
  disconnectScroll: Fn;
  /** The function to call to stop listening to resize events */
  disconnectResize: Fn;
  /** The function to call to register for scroll events */
  onScroll: (cb: Fn) => Fn;
  /** The function to call to register for resize events */
  onResize: (cb: Fn) => Fn;
}

export default Vue.extend({
  props: {
    noProgressEvents: {
      type: Boolean,
      default: false
    }
  },
  data(): Data {
    return {
      onScreen: false,
      progress: 0
    };
  },
  mounted() {
    const observer = new IntersectionObserver(this.onViewChange);
    observer.observe(this.$el);
    const opts: Options = this.$options as Options;
    const noop = () => {};
    opts.onScroll = createThrottleListener("scroll", window);
    opts.onResize = createThrottleListener("resize", window);
    opts.disconnectResize = noop;
    opts.disconnectScroll = noop;
    opts.observer = observer;
    opts.docRect = createRect();
    opts.elRect = createRect();
    opts.vpRect = createRect();
  },
  beforeDestroy() {
    const opts = this.$options as Options;
    this.onLeaveView();
    opts.observer.unobserve(this.$el);
  },
  methods: {
    onViewChange(
      list: IntersectionObserverEntry[],
      observer: IntersectionObserver
    ) {
      const [entry] = list;
      this.onScreen = entry.isIntersecting;
      if (this.onScreen) {
        this.onEnterView();
      } else {
        this.onLeaveView();
      }
    },
    onEnterView() {
      const opts = this.$options as Options;
      const moveCallback = () => {
        this.onCalculateProgress();
      };
      opts.disconnectResize = opts.onScroll(moveCallback);
      opts.disconnectScroll = opts.onResize(moveCallback);
      this.$emit("onScreen", true);
      moveCallback();
    },
    onLeaveView() {
      const opts: Options = this.$options as Options;
      opts.disconnectScroll();
      opts.disconnectResize();
      this.onCalculateProgress();
      this.$emit("onScreen", false);
    },
    onCalculateProgress() {
      const opts = this.$options as Options;
      opts.elRect = elementRect(this.$el as HTMLElement, opts.elRect);
      opts.vpRect = viewportRect(window, opts.vpRect);
      opts.docRect = documentRect(window, opts.docRect);

      const progress = clamp(
        percentScrolled(opts.elRect, opts.vpRect, opts.docRect),
        0,
        1
      );
      this.progress = progress;
      if (!this.noProgressEvents) {
        this.$emit("progress", progress);
      }
    }
  }
});
