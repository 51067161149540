import { Point } from "./Point";
import { max, min } from "../math/FMath";

export interface Rectangle extends Point {
  width: number;
  height: number;
}

export function right(rect: Rectangle): number {
  return rect.x + rect.width;
}

export function bottom(rect: Rectangle): number {
  return rect.y + rect.height;
}

/** The X-coordinate of the center of the Bounds. */
export function centerX(rect: Rectangle): number {
  return rect.x + rect.width * 0.5;
}

/** The Y-coordinate of the center of the Bounds. */
export function centerY(rect: Rectangle): number {
  return rect.y + rect.height * 0.5;
}

export function create(
  x: number = 0,
  y: number = 0,
  width: number = 0,
  height: number = 0
): Rectangle {
  return assign({} as Rectangle, x, y, width, height);
}

/**
 *
 * @param rect The rectangle to modify its properties
 * @param x The x
 * @param y The y
 * @param width The width
 * @param height The height
 */
export function assign(
  rect: Rectangle,
  x: number = 0,
  y: number = 0,
  width: number = 0,
  height: number = 0
) {
  rect.x = x;
  rect.y = y;
  rect.width = width;
  rect.height = height;
  return rect;
}

/**
 * Copies values from one bounds to another.
 * @param source The source bounds to copy values from
 * @param target The target bounds to copy to
 */
export function assignTo(source: Rectangle, target: Rectangle) {
  assign(target, source.x, source.y, source.width, source.height);
}

/**
 *
 * @param r1 The first Rectangle to check for intersection
 * @param r2 The second Rectangle to check for intersection
 * @param result If supplied and the rectangle intersect, will be set to the calculated intersection rectangle.
 */
export function intersects(
  r1: Rectangle,
  r2: Rectangle,
  result?: Rectangle
): boolean {
  const leftSide = max(r1.x, r2.x);
  const rightSide = min(right(r1), right(r2));
  if (leftSide > rightSide) {
    return false;
  }

  const top = max(r1.y, r2.y);
  const bottomSide = min(bottom(r1), bottom(r2));
  if (top > bottomSide) {
    return false;
  }

  if (result) {
    assign(result, leftSide, top, rightSide - leftSide, bottomSide - top);
  }

  return true;
}

/**
 * Calculates a new rectangle with passed in pixel margins (positive values move outward, negative values move inward).
 * This changes the overall size of the rectangle as well as its x and y coordinates.
 * You may optionally pass in an object to mutate, rather than creating a new one.
 * Passing negative values in will shrink the rectangle.
 *
 * @param rect The rectangle to modify. A negative value will shrink the rectangle
 * @param left The left edge to translate by. A negative value will shrink the rectangle
 * @param right The right edge to translate by. A negative value will shrink the rectangle
 * @param bottom The bottom edge to translate by. A negative value will shrink the rectangle
 * @param top The top edge to translate by. A negative value will shrink the rectangle
 */
export function margin(
  rect: Rectangle,
  offsetTop: number,
  offsetRight: number,
  offsetBottom: number,
  offsetLeft: number,
  result?: Rectangle
): Rectangle {
  const output: Rectangle = result ? result : create();
  const topEdge: number = rect.y - offsetTop;
  const bottomEdge: number = bottom(rect) + offsetBottom;
  const leftEdge: number = rect.x - offsetLeft;
  const rightEdge: number = right(rect) + offsetRight;
  return assign(
    output,
    -offsetLeft + rect.x,
    -offsetTop + rect.y,
    rightEdge - leftEdge,
    bottomEdge - topEdge
  );
}

/**
 * Scales a rectangle around a given x y in local coordinates
 *
 * @param rect The rectangle to scale
 * @param scaleX The percentage to scale along the x axis
 * @param scaleY The percentage to scale along the y axis
 * @param originX The point in local coordinates to scale around (0 being the left side)
 * @param originY The point in local coordinates to scale around (0 beind the top side)
 */
export function scale(
  rect: Rectangle,
  scaleX: number,
  scaleY: number,
  originX: number,
  originY: number,
  result?: Rectangle
) {
  const output: Rectangle = result ? result : create();
  return assign(
    output,
    scaleX * originX,
    scaleY * originY,
    rect.width * scaleX,
    rect.height * scaleY
  );
}
