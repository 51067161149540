export interface DynamicComponent {
  type: string;
  props?: any;
}

export interface ProgressiveImage {
  src: string;
  preSrc: string;
}

export interface BackgroundVideo {
  src: string;
  mobileSrc?: string;
  poster: ProgressiveImage;
}

export interface NavMeta {
  headline?: string;
  color?: string;
  link?: string;
  sort?: number;
}

export interface SectionMeta {
  id: string;
  headline: string;
  nav?: NavMeta;
  // The type of section. Current valid values are hero and sectionblade
  type?: string;
  icon?: DynamicComponent;
  color: string;
  bgColor?: string;
}

// Todo -- Define data needed for editorial section heer
export interface EditorialData extends SectionMeta {
  sub: string;
}

export interface SectionData extends SectionMeta {
  // headline: string;
  articles: CoverArticle[];
}

/**
 * Cover article definition. May contain sub-articles
 */
export interface CoverArticle extends Article {
  /** The side the caption is on. */
  side?: Side;

  fullHeight?: boolean;

  /** Dark text captions w/ white background */
  darkCaption?: boolean;

  /** The side the articles should start on, alternating from side to side in the column. */
  subStartSide?: Side;

  /** The shorter articles */
  subArticles: Article[];
}

/**
 * Base interface for all articles (cover or sub articles)
 */
export interface Article {
  /** The headline text */
  head: string;

  /** The size of the header in the caption */
  headSize?: Size;

  /** The url to link to when clicking the article */
  url?: string;

  /** The subhead / description text */
  sub: string;

  /** The background image or video to use */
  bg: ProgressiveImage | BackgroundVideo;
}

export const enum Side {
  left = "left",
  right = "right"
}

export const enum Size {
  small = "sz-small",
  standard = "sz-standard",
  medium = "sz-med",
  large = "sz-large"
}
