








































































































































































































import Vue from "vue";
import { mapState } from "vuex";
import Parallax from "@/components/fx/Parallax.vue";
import Caption from "@/components/Caption.vue";
import { Side, Size } from "../js/data-types";

export default Vue.extend({
  components: {
    Caption,
    Parallax
  },
  props: {
    head: {
      type: String
    },
    text: {
      type: String
    },
    url: {
      type: String
    },
    headSize: {
      type: String as () => Size,
      default: Size.standard
    },
    // Can only be left or right!
    side: {
      type: String as () => Side,
      default: Side.left
    },
    subStartSide: {
      type: String as () => Side,
      default: Side.left
    },
    darkCaption: {
      type: Boolean,
      default: false
    },
    fullHeight: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState(["mobile"])
  }
});
