



















































import Vue, { VueConstructor } from "vue";
import { createThrottleListener } from "@/js/util/Listeners";
interface Ref {
  $refs: {
    bg: HTMLVideoElement | HTMLImageElement;
  };
}
export default (Vue as VueConstructor<Vue & Ref>).extend({
  props: {
    tag: {
      type: String,
      default: "div"
    },
    fit: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: "img"
    },
    src: {
      type: String,
      required: true
    },
    poster: {
      type: String
    },
    loop: {
      type: Boolean,
      default: true
    },
    enabled: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      width: 0,
      height: 0,
      fitWidth: 0,
      fitHeight: 0,
      loaded: false,
      landscape: false,
      isMounted: false
    };
  },
  computed: {
    bgRatio(): number {
      return this.height != 0 ? this.width / this.height : 0;
    },
    bgStyle() {
      const ls = (this.landscape && !this.fit) || (!this.landscape && this.fit);
      if (!ls) {
        return {
          width: "unset",
          height: "100%"
        };
      }
      return {
        width: "100%",
        height: "unset"
      };
    },
    monitor(): boolean {
      return this.isMounted && this.enabled;
    }
  },
  watch: {
    monitor(shouldEnable) {
      this.onEnable(shouldEnable);
      const vid: HTMLVideoElement = (this.$refs as any).bg;
      if (shouldEnable) {
        vid.play();
      } else if (vid) {
        vid.pause();
      }
    },
    src(val: string) {
      if (this.type == "video") {
        this.$nextTick(() => {
          if (this.enabled) {
            this.play(true);
          }
        });
      }
    }
  },
  mounted() {
    const opts: any = this.$options;
    opts.onResize = createThrottleListener("resize", window);
    opts.disconnectResize = () => {};

    if (this.poster && this.type == "video") {
      const img = new Image();
      img.onload = e => {
        this.width = img.naturalWidth;
        this.height = img.naturalWidth;
        this.onResize();
      };
      img.src = this.poster;
    }
    this.isMounted = true;
  },
  beforeDestroy() {
    this.isMounted = false;
    (this.$options as any).disconnectResize();
  },
  methods: {
    onEnable(shouldEnable: boolean) {
      const opts: any = this.$options;
      if (shouldEnable) {
        opts.disconnectResize = opts.onResize(() => this.onResize());
        this.onResize();
      } else {
        opts.disconnectResize();
      }
      if (this.type == "video" && this.loaded) {
        this.play(shouldEnable);
      }
    },
    play(shouldPlay: boolean) {
      if (this.$refs.bg instanceof HTMLVideoElement) {
        if (shouldPlay) {
          const prom = this.$refs.bg.play();
          if (prom) {
            prom.catch(err => {
              console.log("Video playback error.");
              console.log(err);
            });
          }
        } else {
          this.$refs.bg.pause();
        }
      }
    },
    onResize() {
      // Get the parent width and height
      const parent: HTMLElement = this.$el as HTMLElement;
      const pWidth = parent.offsetWidth;
      const pHeight = parent.offsetHeight;
      const containerRatio = pWidth / pHeight; // Aspect ratio of the containing div.
      // image is more landscape than the parent div - fit to width
      this.landscape = this.bgRatio < containerRatio;
    },
    onImgLoad(evt: any) {
      const bg: HTMLImageElement = evt.target as any;
      this.width = evt.target.naturalWidth;
      this.height = evt.target.naturalHeight;
      this.loaded = true;
      this.onResize();
      this.$emit("loaded", true);
    },
    onVideoLoad(evt: any) {
      const bg: HTMLVideoElement = evt.target as any;
      this.width = bg.videoWidth;
      this.height = bg.videoHeight;
      this.loaded = true;
      this.onResize();
      this.$emit("loaded", true);
    }
  }
});
