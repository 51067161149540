//
// Flambe - Rapid game development
// https://github.com/aduros/flambe/blob/master/LICENSE.txt

/**
 * Some handy math functions, and inlinable constants.
 */
export const E = 2.718281828459045;
export const LN2 = 0.6931471805599453;
export const LN10 = 2.302585092994046;
export const LOG2E = 1.4426950408889634;
export const LOG10E = 0.43429448190325176;
export const PI = 3.141592653589793;
export const SQRT1_2 = 0.7071067811865476;
export const SQRT2 = 1.4142135623730951;

// Haxe doesn't specify the size of an int or float, in practice it's 32 bits
/** The lowest integer value in Flash and JS. */
export const INT_MIN: number = -2147483648;

/** The highest integer value in Flash and JS. */
export const INT_MAX: number = 2147483647;

/** The lowest float value in Flash and JS. */
export const FLOAT_MIN = -1.79769313486231e308;

/** The highest float value in Flash and JS. */
export const FLOAT_MAX = 1.79769313486231e308;

/** Converts an angle in degrees to radians. */
export function toRadians(degrees: number): number {
  return (degrees * PI) / 180;
}

/** Converts an angle in radians to degrees. */
export function toDegrees(radians: number): number {
  return (radians * 180) / PI;
}

export function max(a: number, b: number): number {
  return a > b ? a : b;
}

export function min(a: number, b: number): number {
  return a < b ? a : b;
}

export function clamp(value: number, min: number, max: number): number {
  if (value < min) return min;
  if (value > max) return max;
  return value;
}

export function sign(value: number): number {
  if (value < 0) return -1;
  if (value > 0) return 1;
  return 0;
}
