



































import "@/assets/css/_animation.scss";

import Vue from "vue";
import { mapState } from "vuex";
import mtv from "../layouts/mtv.vue";
import InView from "../layouts/sections/InView.vue";
import ThreeUp from "../layouts/sections/ThreeUp.vue";
import Editorial from "../layouts/sections/Editorial.vue";
import Hero from "../components/Hero.vue";
// import NavModal from "@/components/NavModal.vue";
import { SectionData } from "../js/data-types";

function loadNav(): Promise<any> {
  if ("scrollBehavior" in document.documentElement.style) {
    console.log("Has smoothscroll, don't load polyfill.");
    return import("@/components/NavModal.vue");
  }

  return new Promise(async (resolve, reject) => {
    const proms = await Promise.all([
      import("smoothscroll-polyfill"),
      import("@/components/NavModal.vue")
    ]);
    const [smoothscroll] = proms;
    smoothscroll.polyfill();
    console.log("Polyfilling");
    resolve(Promise.resolve(proms[1]));
  });
}

export default Vue.extend({
  components: {
    mtv,
    NavModal: () => loadNav(),
    Hero,
    Editorial,
    ThreeUp
  },
  computed: {
    ...mapState(["sections", "heroLoaded", "navOpen"])
  }
});
