/** Linear, no easing. */
export function linear(t) {
  return t;
}

/** Quadratic in. */
export function quadIn(t) {
  return t * t;
}

/** Quadratic out. */
export function quadOut(t) {
  return t * (2 - t);
}

/** Quadratic in and out. */
export function quadInOut(t) {
  return t <= 0.5 ? t * t * 2 : 1 - --t * t * 2;
}

/** Quadratic out and in */
export function quadOutIn(t) {
  return t < 0.5
    ? -0.5 * (t = t * 2) * (t - 2)
    : 0.5 * (t = t * 2 - 1) * t + 0.5;
}

/** Cubic in. */
export function cubeIn(t) {
  return t * t * t;
}

/** Cubic out. */
export function cubeOut(t) {
  return 1 + --t * t * t;
}

/** Cubic in and out. */
export function cubeInOut(t) {
  return t <= 0.5 ? t * t * t * 4 : 1 + --t * t * t * 4;
}

/** Cubic out and in. */
export function cubeOutIn(t) {
  return 0.5 * ((t = t * 2 - 1) * t * t + 1);
}

/** Quartic in. */
export function quartIn(t) {
  return t * t * t * t;
}

/** Quartic out. */
export function quartOut(t) {
  return 1 - --t * t * t * t;
}

/** Quartic in and out. */
export function quartInOut(t) {
  return t <= 0.5
    ? t * t * t * t * 8
    : (1 - (t = t * 2 - 2) * t * t * t) / 2 + 0.5;
}

/** Quartic out and in */
export function quartOutIn(t) {
  return t < 0.5
    ? -0.5 * (t = t * 2 - 1) * t * t * t + 0.5
    : 0.5 * (t = t * 2 - 1) * t * t * t + 0.5;
}

/** Quintic in. */
export function quintIn(t) {
  return t * t * t * t * t;
}

/** Quintic out. */
export function quintOut(t) {
  return (t = t - 1) * t * t * t * t + 1;
}

/** Quintic in and out. */
export function quintInOut(t) {
  return (t *= 2) < 1
    ? (t * t * t * t * t) / 2
    : ((t -= 2) * t * t * t * t + 2) / 2;
}

/** Quintic out and in. */
export function quintOutIn(t) {
  return 0.5 * ((t = t * 2 - 1) * t * t * t * t + 1);
}

/** Sine in. */
export function sineIn(t) {
  return 1 - Math.cos(PIhalf * t);
}

/** Sine out. */
export function sineOut(t) {
  return Math.sin(PIhalf * t);
}

/** Sine in and out. */
export function sineInOut(t) {
  return 0.5 - Math.cos(PI * t) / 2;
}

/** Sine out and in. */
export function sineOutIn(t) {
  if (t == 0) return 0;
  else if (t == 1) return 1;
  else
    return t < 0.5
      ? 0.5 * Math.sin(t * 2 * PIhalf)
      : -0.5 * Math.cos((t * 2 - 1) * PIhalf) + 1;
}

/** Bounce in. */
export function bounceIn(t) {
  t = 1 - t;
  if (t < B1) return 1 - 7.5625 * t * t;
  if (t < B2) return 1 - (7.5625 * (t - B3) * (t - B3) + 0.75);
  if (t < B4) return 1 - (7.5625 * (t - B5) * (t - B5) + 0.9375);
  return 1 - (7.5625 * (t - B6) * (t - B6) + 0.984375);
}

/** Bounce out. */
export function bounceOut(t) {
  if (t < B1) return 7.5625 * t * t;
  if (t < B2) return 7.5625 * (t - B3) * (t - B3) + 0.75;
  if (t < B4) return 7.5625 * (t - B5) * (t - B5) + 0.9375;
  return 7.5625 * (t - B6) * (t - B6) + 0.984375;
}

/** Bounce in and out. */
export function bounceInOut(t) {
  if (t < 0.5) {
    t = 1 - t * 2;
    if (t < B1) return (1 - 7.5625 * t * t) / 2;
    if (t < B2) return (1 - (7.5625 * (t - B3) * (t - B3) + 0.75)) / 2;
    if (t < B4) return (1 - (7.5625 * (t - B5) * (t - B5) + 0.9375)) / 2;
    return (1 - (7.5625 * (t - B6) * (t - B6) + 0.984375)) / 2;
  }
  t = t * 2 - 1;
  if (t < B1) return (7.5625 * t * t) / 2 + 0.5;
  if (t < B2) return (7.5625 * (t - B3) * (t - B3) + 0.75) / 2 + 0.5;
  if (t < B4) return (7.5625 * (t - B5) * (t - B5) + 0.9375) / 2 + 0.5;
  return (7.5625 * (t - B6) * (t - B6) + 0.984375) / 2 + 0.5;
}

/** Circle in. */
export function circIn(t) {
  return 1 - Math.sqrt(1 - t * t);
}

/** Circle out. */
export function circOut(t) {
  --t;
  return Math.sqrt(1 - t * t);
}

/** Circle in and out. */
export function circInOut(t) {
  return t <= 0.5
    ? (Math.sqrt(1 - t * t * 4) - 1) / -2
    : (Math.sqrt(1 - (t * 2 - 2) * (t * 2 - 2)) + 1) / 2;
}

/** Circle out and in. */
export function circOutIn(t) {
  return t < 0.5
    ? 0.5 * Math.sqrt(1 - (t = t * 2 - 1) * t)
    : -0.5 * (Math.sqrt(1 - (t = t * 2 - 1) * t) - 1 - 1);
}

/** Exponential in. */
export function expoIn(t) {
  return Math.pow(2, 10 * (t - 1));
}

/** Exponential out. */
export function expoOut(t) {
  return -Math.pow(2, -10 * t) + 1;
}

/** Exponential in and out. */
export function expoInOut(t) {
  return t < 0.5
    ? Math.pow(2, 10 * (t * 2 - 1)) / 2
    : (-Math.pow(2, -10 * (t * 2 - 1)) + 2) / 2;
}

/** Exponential out and in. */
export function expoOutIn(t) {
  return t < 0.5
    ? 0.5 * (1 - Math.pow(2, -20 * t))
    : t == 0.5
    ? 0.5
    : 0.5 * (Math.pow(2, 20 * (t - 1)) + 1);
}

/** Back in. */
export function backIn(t) {
  return t * t * (2.70158 * t - 1.70158);
}

/** Back out. */
export function backOut(t) {
  return 1 - --t * t * (-2.70158 * t - 1.70158);
}

/** Back in and out. */
export function backInOut(t) {
  t *= 2;
  if (t < 1) return (t * t * (2.70158 * t - 1.70158)) / 2;
  t -= 2;
  return (1 - t * t * (-2.70158 * t - 1.70158)) / 2 + 0.5;
}

/** Elastic in. */
export function elasticIn(t) {
  return -(
    ELASTIC_AMPLITUDE *
    Math.pow(2, 10 * (t -= 1)) *
    Math.sin(
      ((t - (ELASTIC_PERIOD / PI2) * Math.asin(1 / ELASTIC_AMPLITUDE)) * PI2) /
        ELASTIC_PERIOD
    )
  );
}

/** Elastic out. */
export function elasticOut(t) {
  return (
    ELASTIC_AMPLITUDE *
      Math.pow(2, -10 * t) *
      Math.sin(
        ((t - (ELASTIC_PERIOD / PI2) * Math.asin(1 / ELASTIC_AMPLITUDE)) *
          PI2) /
          ELASTIC_PERIOD
      ) +
    1
  );
}

/** Elastic in and out. */
export function elasticInOut(t) {
  if (t < 0.5) {
    return (
      -0.5 *
      (Math.pow(2, 10 * (t -= 0.5)) *
        Math.sin(((t - ELASTIC_PERIOD / 4) * PI2) / ELASTIC_PERIOD))
    );
  }
  return (
    Math.pow(2, -10 * (t -= 0.5)) *
      Math.sin(((t - ELASTIC_PERIOD / 4) * PI2) / ELASTIC_PERIOD) *
      0.5 +
    1
  );
}

export const PI = 3.141592653589793;
export const PIhalf = PI / 2;
export const PI2 = PI * 2;
export const B1 = 1 / 2.75;
export const B2 = 2 / 2.75;
export const B3 = 1.5 / 2.75;
export const B4 = 2.5 / 2.75;
export const B5 = 2.25 / 2.75;
export const B6 = 2.625 / 2.75;
export const ELASTIC_AMPLITUDE = 1;
export const ELASTIC_PERIOD = 0.4;
