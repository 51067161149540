var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('InView',{staticClass:"blade",style:(_vm.sectionStyles),attrs:{"noProgressEvents":true},on:{"onScreen":_vm.onScreenChange},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var progress = ref.progress;
var sectionOnScreen = ref.onScreen;
return [_c('div',{staticClass:"head"},[_c('h2',[_vm._v("\n        "+_vm._s(_vm.config.headline)),(_vm.config.icon && !_vm.$store.state.ssr)?_c(_vm.config.icon.type,_vm._b({tag:"component"},'component',_vm.config.icon.props,false)):_vm._e()],1)]),_vm._l((_vm.config.articles),function(main,i){return _c('InView',{key:'main' + i,attrs:{"noProgressEvents":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var progress = ref.progress;
var onScreen = ref.onScreen;
return [_c('MainArticle',{attrs:{"head":main.head,"headSize":main.headSize,"text":main.sub,"side":main.side,"url":main.url,"darkCaption":main.darkCaption,"subStartSide":main.subStartSide,"fullHeight":main.fullHeight},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('transition',{attrs:{"name":"fade-section"}},[_c(main.bg.poster ? 'CoverVideo' : 'CoverImage',{directives:[{name:"show",rawName:"v-show",value:(onScreen),expression:"onScreen"}],tag:"component",staticClass:"mainBg",attrs:{"url":main.bg,"enabled":onScreen},on:{"loaded":_vm.onImgLoad}},[(_vm.mobile)?_c('Caption',{staticClass:"main-cap-mobile",attrs:{"head":main.head,"url":main.url,"text":main.sub,"darkText":main.darkCaption,"headSize":main.headSize}}):_vm._e()],1)],1)]},proxy:true},{key:"sub",fn:function(){return [_vm._l((main.subArticles),function(small,i){return [_c('FlickerTransition',{key:'sub' + i,attrs:{"duration":1,"scale":0.3,"delay":i * 0.3}},[_c('SubArticle',{directives:[{name:"show",rawName:"v-show",value:(
                    _vm.mobile || (progress > 0.2 + i * 0.1 && sectionOnScreen)
                  ),expression:"\n                    mobile || (progress > 0.2 + i * 0.1 && sectionOnScreen)\n                  "}],key:'sub__' + i,attrs:{"head":small.head,"text":small.sub,"url":small.url}},[_c('Percent',{attrs:{"min":0.8,"max":1.5,"in":progress},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var value = ref.value;
return [_c('CoverImage',{style:({
                          transform: ("scale(" + value + ") translateY(" + (value *
                            6) + "%)")
                        }),attrs:{"url":small.bg},on:{"loaded":_vm.onImgLoad}})]}}],null,true)})],1)],1)]})]},proxy:true}],null,true)})]}}],null,true)})})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }