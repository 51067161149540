import {
  SectionData,
  Side,
  ProgressiveImage,
  Size,
  SectionMeta,
  EditorialData
} from "./data-types";

/**
 * The hero related data
 */
export const hero: SectionMeta = {
  id: "home",
  headline: "HOME",
  type: "hero",
  color: "#FFFFFF"
};

/**
 * The content for
 */
export const editorial: EditorialData = {
  id: "editorial",
  nav: {
    headline: "Editor's Note",
    color: "#FFFFFF",
    sort: -1
  },
  type: "editorial",
  color: "#000000",
  bgColor: "#F9CD04",
  headline: "01.LOVE NOW",
  sub:
    "Chronicling how we find and experience romance in a culture where everything is filtered through a screen. <a href='http://www.mtv.com/news/3143782/volume-love-now-editors-letter/' target='_blank'><nobr>Editor’s note</nobr></a>"
};

/**
 * The playlist related data
 */
export const playlist: SectionData = {
  id: "playlist",
  color: "#EA018B",
  bgColor: "#000000",
  headline: "PLAYLIST",
  icon: {
    type: "IcoPlaylist"
  },
  articles: [
    {
      side: Side.left,
      headSize: Size.large,
      darkCaption: false,
      // darkCaption: true,
      // bg: {
      //   src: require("@/assets/v1/placeholder-playlist-bg.mp4"),
      //   poster: require("lqip-loader!@/assets/v1/placeholder-playlist-bg.jpg") as ProgressiveImage
      // },
      bg: {
        poster: require("lqip-loader!@/assets/v1/photo/playlist/main.jpg"),
        src: require("@/assets/v1/photo/playlist/main.mp4")
      },
      head: "25 Songs: <br>For Big Feelings, Breakups, and Bold Moves",
      sub: "From Camila and Carly Rae to BTS and Billie, your playlist awaits.",
      url:
        "http://www.mtv.com/news/3143133/volume-playlist-love-songs-breakup-tracks/",
      subStartSide: Side.left,
      subArticles: [
        {
          bg: require("lqip-loader!@/assets/v1/photo/playlist/kim.jpg"),
          head: "Pop's Baddest Bitch",
          sub:
            "How Kim Petras froze out her ex, iced over her insecurities, and became a star.",
          url:
            "http://www.mtv.com/news/3143104/kim-petras-volume-interview-turn-off-the-light/"
        },
        {
          bg: require("lqip-loader!@/assets/v1/photo/playlist/self-love.jpg"),
          head: "Alone, Together: The Rising Sound of Self-Love",
          sub:
            "Katy Perry, Tove Lo, and more are picking up where Ariana Grande left off.",
          url:
            "http://www.mtv.com/news/3143108/ariana-grande-lizzo-katy-perry-self-love-pop-music/"
        }
      ]
    }
  ]
};

/**
 * The look related data
 */
export const look: SectionData = {
  id: "cover",
  color: "#57FFFF",
  bgColor: "#000000",
  headline: "COVER",
  icon: {
    type: "IcoLook"
  },
  articles: [
    {
      side: Side.right,
      darkCaption: false,
      // darkCaption: true,
      subStartSide: Side.right,
      headSize: Size.large,
      url:
        "http://www.mtv.com/news/3143092/liam-payne-volume-cover-mental-health-fatherhood/",
      bg: require("lqip-loader!@/assets/v1/photo/main-article-primary_0000_cover.jpg"),
      head: "Liam Payne Stripped Down",
      sub:
        "The global superstar opens up to MTV News about what matters most: mental health, fatherhood, and defining his life on his own terms.",
      subArticles: [
        // {
        //   bg: require("lqip-loader!@/assets/v1/photo/look/sub-1.jpg"),
        //   head: "An oral history of the love song of decade",
        //   sub:
        //     "We explore the making of 'Sucker' by the Jonas Brothers, featuring anecdotes from the Jonas Brothers, their wives, and producers who worked on it."
        // },
        // {
        //   bg: require("lqip-loader!@/assets/v1/photo/look/sub-2.jpg"),
        //   head: "Alone, together: the rising sound of self-love in pop",
        //   sub:
        //     "A trend piece examing notable break-up anthems in 2019 (LAUV and Julia Michaels, Lil Xan and Noah Cyrus, Pete Davidson and Ariana)"
        // }
      ]
    }
  ]
};

/**
 * The trending related data
 */
export const trending: SectionData = {
  id: "trending",
  color: "#F9CD04",
  bgColor: "#000000",
  headline: "TRENDING",
  icon: {
    type: "IcoTrending"
  },
  articles: [
    {
      fullHeight: true,
      side: Side.left,
      darkCaption: false,
      // darkCaption: true,
      headSize: Size.large,
      bg: require("lqip-loader!@/assets/v1/photo/trending/main-1.jpg") as ProgressiveImage,
      // bg: {
      //   src: require("@/assets/v1/trending-placeholder-bg.mp4"),
      //   poster: require("lqip-loader!@/assets/v1/trending-placeholder-bg.jpg")
      // },
      head: "The Big Business of 'Instacouples'",
      sub:
        "Influencers drive as much advertising today as traditional models, but monetizing a relationship has its challenges.",
      url:
        "http://www.mtv.com/news/3143111/instagram-couples-brand-deals-sponsorships-relationship-challenges/",
      subStartSide: Side.left,
      subArticles: [
        {
          bg: require("lqip-loader!@/assets/v1/photo/trending/sub-1.jpg"),
          head: "<nobr>Rom-com</nobr> Reborn ",
          sub:
            "Complete with fresh faces and modern messes, the tropes set forth by <em>When Harry Met Sally</em>… get a necessary twist.",
          url:
            "http://www.mtv.com/news/3143115/crazy-rich-asians-last-christmas-rom-com-revival/"
        },
        {
          bg: require("lqip-loader!@/assets/v1/photo/trending/sub-2.jpg"),
          head: "How These Couples Are Queering 'Straight' Marriage",
          sub:
            "The institution has been due for disruption, even in seemingly heterosexual partnerships.",
          url:
            "http://www.mtv.com/news/3143120/queer-couples-redefining-straight-marriages/"
        }
      ]
    },
    {
      side: Side.left,
      headSize: Size.large,
      darkCaption: false,
      // darkCaption: true,
      bg: require("lqip-loader!@/assets/v1/photo/trending/main-2.jpg"),
      head: "How the Social Media Censorship of Sex Workers Affects Us All",
      sub:
        "The removal and suppression of marginalized content creators from online platforms dictates whose voices and stories deserve to be heard.",
      url: "http://www.mtv.com/news/3143241/sex-work-censorship-effects/",
      subStartSide: Side.left,
      subArticles: [
        // {
        //   bg: require("lqip-loader!@/assets/v1/photo/trending/sub-3.jpg"),
        //   head: "An oral history of the love song of decade",
        //   sub:
        //     "We explore the making of 'Sucker' by the Jonas Brothers, featuring anecdotes from the Jonas Brothers, their wives, and producers who worked on it."
        // },
        {
          bg: require("lqip-loader!@/assets/v1/photo/trending/sub-3.jpg"),
          head: "In Fan Fiction, Love Is Limitless",
          sub:
            "How fans cultivate creativity and community online — and find themselves in the process.",
          url:
            "http://www.mtv.com/news/3143123/shipping-fanfic-fan-fiction-explained/"
        }
      ]
    }
  ]
};

/**
 * The study related data
 */
export const study: SectionData = {
  id: "study",
  // type: "study",
  color: "#66ff00",
  bgColor: "#000000",
  headline: "STUDY",
  icon: {
    type: "IcoStudy"
  },
  articles: [
    {
      side: Side.left,
      darkCaption: false,
      // darkCaption: true,
      headSize: Size.large,
      bg: {
        poster: require("lqip-loader!@/assets/v1/photo/study/study-main.jpg"),
        src: require("@/assets/v1/photo/study/study-main.mp4")
      },
      head: "One-Third of Gen Z Decides Who They Date Based On Astrology",
      sub:
        "Scorpio season be damned! For young people who believe love is written in the stars, Aquarius is their preferred sign, a new study reveals.",
      url:
        "http://www.mtv.com/news/3143126/horoscopes-zodiac-love-compatibility-millennials-gen-z/",
      subStartSide: Side.right,
      subArticles: [
        {
          bg: require("lqip-loader!@/assets/v1/photo/study/sub-1.jpg"),
          head: "Welcome to Tinder Purgatory",
          sub:
            "The internet didn't change the way we meet others — it's designed it entirely. For those who seek connection online, dating digitally is its own unique hell.",
          url:
            "http://www.mtv.com/news/3143129/dating-app-tinder-relationships-survey/"
        }
      ]
    }
  ]
};
