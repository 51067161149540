import { Rectangle } from "../geom/Rectangle";
import { create as createBounds, assign } from "../geom/Rectangle";
import { max } from "../math/FMath";

/**
 * Takes an HTML element and returns its global x, y, width, and height.
 * Fast, but does not take into account any CSS transforms
 *
 * @param el The element to evaluate
 */
export function elementRect(el: HTMLElement, reuse?: Rectangle): Rectangle {
  const w: number = el.offsetWidth;
  const h: number = el.offsetHeight;
  const output: Rectangle = reuse
    ? assign(reuse, 0, 0, w, h)
    : createBounds(0, 0, w, h);

  let curr: HTMLElement | null = el;
  while (curr) {
    output.y += curr.offsetTop;
    output.x += curr.offsetLeft;
    curr = curr.offsetParent as HTMLElement;
  }
  return output;
}

export function viewportRect(win: Window, result?: Rectangle) {
  return assign(
    result || createBounds(),
    win.scrollX,
    win.scrollY,
    win.innerWidth,
    win.innerHeight
  );
}

/**
 *
 * @param win The window object to use (typically window)
 * @param result If passed in, will assign values to this object rather than creating a new object.
 */
export function documentRect(win: Window, result?: Rectangle): Rectangle {
  let scrollHeight = max(
    win.document.body.scrollHeight,
    win.document.documentElement.scrollHeight
  );
  scrollHeight = max(scrollHeight, win.document.body.offsetHeight);
  scrollHeight = max(scrollHeight, win.document.documentElement.offsetHeight);
  scrollHeight = max(scrollHeight, win.document.documentElement.offsetHeight);
  scrollHeight = max(scrollHeight, win.document.body.clientHeight);
  scrollHeight = max(scrollHeight, win.document.documentElement.clientHeight);

  let scrollWidth = max(
    win.document.body.scrollWidth,
    win.document.documentElement.scrollWidth
  );
  scrollWidth = max(scrollWidth, win.document.body.offsetWidth);
  scrollWidth = max(scrollWidth, win.document.documentElement.offsetWidth);
  scrollWidth = max(scrollWidth, win.document.documentElement.offsetWidth);
  scrollWidth = max(scrollWidth, win.document.body.clientWidth);
  scrollWidth = max(scrollWidth, win.document.documentElement.clientWidth);

  return assign(result || createBounds(), 0, 0, scrollWidth, scrollHeight);
}
