import { Rectangle, bottom, intersects, create } from "../geom/Rectangle";
import { min } from "../math/FMath";

/**
 * Calculates the percentage that an element bounding has scrolled in the given viewport.
 *
 * @param el The bounding rectangle of the element in window coordinates
 * @param vp The current viewport position to calculate (i.e. camera)
 * @param doc The bounds of the entire document
 * @param rect optinally pass in a rectangle to set with the area of intersection
 */
export function percent(
  el: Rectangle,
  vp: Rectangle,
  doc: Rectangle,
  rect?: Rectangle
): number {
  const intersection: Rectangle = rect || create();

  if (intersects(el, vp, intersection) && intersection.height > 0) {
    // Above the fold calculations
    if (el.y <= vp.height) {
      const distTotal = bottom(el);
      const distMoved = vp.y;
      return distMoved / distTotal;
    }

    // Adjust calculations if the element is unable to scroll off page due to being at the bottom of the document
    const scrollableHeight: number = bottom(doc) - vp.height;
    const elBottom: number = min(bottom(el), scrollableHeight);

    // below the fold calculations
    const startY = elBottom; // vp.y value to start reacting (percent = 0)
    const endY = el.y - vp.height; // vp.y value to end reacting (percent = 1)
    const distTotal = startY - endY;
    return 1 - (startY - vp.y) / distTotal;
  }

  // Stop calculating
  if (bottom(el) <= vp.y) {
    return 1;
  }

  return 0;
}
