






































































































import Vue from "vue";
import { mapState } from "vuex";
import InView from "./InView.vue";
import { CoverArticle, SectionData } from "@/js/data-types";
import Caption from "@/components/Caption.vue";
import CoverImage from "@/components/fx/CoverImage.vue";
import CoverVideo from "@/components/fx/CoverVideo.vue";
import FlickerTransition from "@/components/fx/FlickerTransition.vue";
import MainArticle from "@/components/MainArticle.vue";
import Percent from "@/components/fx/Percent.vue";
import SubArticle from "@/components/SubArticle.vue";

export default Vue.extend({
  components: {
    Caption,
    CoverImage,
    CoverVideo,
    FlickerTransition,
    InView,
    MainArticle,
    Percent,
    SubArticle
  },
  props: {
    config: {
      type: Object as () => SectionData
    }
  },
  data(): { loadCount: number } {
    return {
      loadCount: 0
    };
  },
  computed: {
    ...mapState(["mobile"]),
    totalImages(): number {
      let imgs: number = 0;
      for (const article of this.config.articles) {
        imgs += article.subArticles.length + 1;
      }
      return imgs;
    },
    sectionStyles(): any {
      return {
        color: this.config.color,
        backgroundColor: this.config.bgColor
      };
    }
  },
  methods: {
    onImgLoad(): void {
      this.loadCount++;
      if (this.loadCount >= this.totalImages) {
        console.log(
          `Loaded ${this.config.headline} images (${this.totalImages})`
        );
      }
    },
    onScreenChange(inView: boolean): void {
      if (inView) {
        // this.$store.commit("setCurrentSection", {
        //   name: this.config.id,
        //   color: this.config.color,
        //   icon: this.config.icon
        // });
      }
    }
  }
});
