




























import Vue, { VueConstructor } from "vue";
import LazyImg from "@/components/fx/LazyImg.vue";
import BackgroundFill from "@/components/fx/BackgroundFill.vue";

import { BackgroundVideo } from "@/js/data-types";
import { mapState } from "vuex";

interface Store {
  mobile: boolean;
}

export default (Vue as VueConstructor<Vue & Store>).extend({
  components: {
    LazyImg,
    BackgroundFill
  },
  data: function() {
    return {
      vidLoaded: false
    };
  },
  props: {
    // The lquip-loader object passed through wich contains a base64 low quality image, and the
    // full quality url.
    url: {
      type: Object as () => BackgroundVideo,
      required: true
    },
    autoLoad: {
      type: Boolean,
      default: true
    },
    blur: {
      type: Boolean,
      default: true
    },
    enabled: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapState(["mobile"]),
    videoSrc(): string {
      return this.url.mobileSrc && this.mobile
        ? this.url.mobileSrc
        : this.url.src;
    }
  },
  methods: {
    onLoaded(): void {
      this.$emit("loaded");
    }
  }
});
