










import Vue from "vue";
import { mapState } from "vuex";
import InView from "./InView.vue";
import { CoverArticle, EditorialData } from "@/js/data-types";
// import CoverImage from "@/components/fx/CoverImage.vue";
// import CoverVideo from "@/components/fx/CoverVideo.vue";

export default Vue.extend({
  components: {
    InView
  },
  props: {
    config: {
      type: Object as () => EditorialData
    }
  },
  computed: {
    ...mapState(["mobile"]),
    sectionStyles(): any {
      return {
        color: this.config.color,
        backgroundColor: this.config.bgColor
      };
    }
  }
});
