





import Vue from "vue";
import { ParallaxElement } from "@/js/util-parallax-horizon";
import { createThrottleListener } from "@/js/util/Listeners";

interface Data {
  windowHeight: number;
  offset: number;
  isMounted: boolean;
}

export default Vue.extend({
  data(): Data {
    return {
      windowHeight: 0,
      offset: 0,
      isMounted: false
    };
  },
  props: {
    tag: {
      type: String,
      default: "div"
    },
    horizon: {
      type: Number,
      required: true
    },
    distance: {
      type: Number,
      required: true
    },
    enabled: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    horizon(val: number): void {
      const opts: any = this.$options;
      const p: ParallaxElement = opts.parallax;
      if (p) {
        opts.parallax.horizon = val;
        this.onScroll();
      }
    },
    distance(val: number): void {
      const opts: any = this.$options;
      const p: ParallaxElement = opts.parallax;
      if (p) {
        opts.parallax.setDist(val);
        this.onScroll();
      }
    },
    monitor(shouldEnable: boolean): void {
      this.onEnable(shouldEnable);
    }
  },
  computed: {
    monitor(): boolean {
      return this.isMounted && this.enabled;
    }
  },
  mounted(): void {
    const opts: any = this.$options;
    opts.onResize = createThrottleListener("resize", window);
    opts.onScroll = createThrottleListener("scroll", window);
    opts.disconnectResize = () => {};
    opts.disconnectScroll = () => {};
    this.isMounted = true;
  },
  methods: {
    // Triggered when the component is both set to 'enabled' and is mounted.
    onEnable(shouldEnable: boolean): void {
      const opts: any = this.$options;
      if (shouldEnable) {
        this.windowHeight = window.innerHeight;
        this.createParallax();
        opts.disconnectResize = opts.onResize(() => this.onResize());
        opts.disconnectScroll = opts.onScroll(() => this.onScroll());
      } else {
        opts.disconnectResize();
        opts.disconnectScroll();
      }
    },
    createParallax(): void {
      const opts: any = this.$options;
      opts.parallax = new ParallaxElement(
        this.$el as HTMLElement,
        this.distance,
        this.horizon
      );
      this.onResize();
    },
    onResize(): void {
      const opts: any = this.$options;
      const parallax: ParallaxElement = opts.parallax;
      parallax.resize(window.innerHeight);
      this.onScroll();
    },
    onScroll(): void {
      const opts: any = this.$options;
      (opts.parallax as ParallaxElement).setPosition(
        window.innerHeight,
        window.scrollY
      );
    }
  }
});
