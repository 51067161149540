
import Vue, { CreateElement, VNode } from "vue";
// Detect if running in a prerendered environment
const isPrerender = navigator.userAgent.indexOf("HeadlessChrome") >= 0;
interface LazyImgData {
  loadCompleted: boolean;
  loading: boolean;
  img: string | undefined;
}
export default Vue.extend({
  props: {
    preload: {
      type: String,
      required: true
    },
    src: {
      type: String,
      required: true
    },
    autoLoad: {
      type: Boolean,
      default: true
    }
  },
  created(): void {
    if (this.autoLoad) {
      this.loadImg();
    }
  },
  watch: {
    autoLoad(val: boolean): void {
      // Watch for changes in the autoLoad value and kick off the load process
      if (val && !this.loading && !this.loadCompleted) {
        this.loadImg();
      }
    },
    preload(_: any): void {
      this.onSrcChange();
    },
    src(_: any): void {
      this.onSrcChange();
    }
  },
  methods: {
    loadImg(): void {
      if (!this.loadCompleted) {
        this.loading = true;
        const i: HTMLImageElement = new Image();
        i.onload = () => this.onLoaded();
        i.onerror = e => {
          console.error(e);
          this.loading = false;
        };
        (this.$options as any).img = i;
        i.src = this.src;
      }
    },
    onLoaded(): void {
      this.loadCompleted = true;
      this.loading = false;
      this.$emit("loaded", this.src);
    },
    // When the source changes, load the new image set.
    onSrcChange(): void {
      this.loading = false;
      this.loadCompleted = false;
      if (this.autoLoad) {
        this.loadImg();
      }
    }
  },
  data(): LazyImgData {
    return {
      loadCompleted: false,
      loading: false,
      img: undefined
    };
  },
  render(h: CreateElement): VNode {
    if (!this.$scopedSlots.default) {
      return h(); // no slots
    }
    if ((this.$scopedSlots as any).default().length > 1) {
      throw "You cannot have more than one root element inside the Percent helper component.";
    }
    return (this.$scopedSlots as any).default({
      loaded: this.loaded,
      bestImg: this.bestImg
    });
  },
  computed: {
    loaded(): boolean {
      return this.loadCompleted;
    },
    bestImg(): string {
      if (isPrerender) {
        return "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"; //this.preload;
      }
      return this.loadCompleted ? this.src : this.preload;
    }
  }
});
