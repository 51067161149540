var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('InView',{staticClass:"hero",style:(_vm.maxHeight),attrs:{"color":_vm.color},on:{"progress":_vm.onProgress,"onScreen":_vm.onScreenChange},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var onScreen = ref.onScreen;
var progress = ref.progress;
return [_c('LazyImg',{attrs:{"preload":_vm.heroImg.preSrc,"src":_vm.heroImg.src},on:{"loaded":_vm.onHeroLoad}}),_c('Percent',{attrs:{"min":0,"max":50,"in":progress,"ease":_vm.ease},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var value = ref.value;
return [_c('BackgroundFill',{staticClass:"content",style:({
            transform: ("translateY(" + value + "%)")
          }),attrs:{"type":"video","enabled":onScreen,"src":_vm.heroVid,"poster":_vm.hero},on:{"loaded":_vm.onHeroVidLoad}},[_c('div',{staticClass:"lockup"},[(!_vm.$store.state.ssr)?_c('VolumeLogoCombined',{ref:"logo",staticClass:"volume",style:({ transform: ("translateY(-" + (value * 6) + "%)") })}):_vm._e()],1)])]}}],null,true)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }