






import Vue from "vue";
import Volume1 from "./pages/Volume1.vue";
export default Vue.extend({
  name: "app",
  components: {
    Volume1
  }
});
