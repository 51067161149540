import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import IcoPlaylist from "@/components/icons/IcoPlaylist.vue";
import IcoLook from "@/components/icons/IcoLook.vue";
import IcoTrending from "@/components/icons/IcoTrending.vue";
import IcoStudy from "@/components/icons/IcoStudy.vue";
// @ts-ignore
import * as tokens from "./assets/css/_tokens.scss";
require("intersection-observer");

Vue.config.productionTip = false;

Vue.component("IcoPlaylist", IcoPlaylist);
Vue.component("IcoLook", IcoLook);
Vue.component("IcoTrending", IcoTrending);
Vue.component("IcoStudy", IcoStudy);

// Detect mobile breakpoint and set VueX state
const mq = window.matchMedia(tokens.bpMobile);
const mqCallback = (e: any) => store.commit("setMobile", e.matches);
mqCallback(mq);
mq.addListener(mqCallback);

new Vue({
  render: h => h(App),
  store,
  mounted: () => document.dispatchEvent(new Event("x-app-rendered"))
}).$mount("#app");
