import Vue from "vue";
import Vuex from "vuex";
import { playlist, editorial, look, trending, study, hero } from "./js/data";

Vue.use(Vuex);

export interface SectionHeader {
  name: string;
  icon?: string;
  color: string;
}

export default new Vuex.Store({
  state: {
    heroLoaded: false,
    navOpen: false,
    mobile: false,
    ssr: navigator.userAgent.indexOf("HeadlessChrome") >= 0,
    section: {
      name: "news",
      color: "white"
    },
    // Use the below line to add the editorial section
    // sections: [hero, editorial, playlist, look, trending, study]
    sections: [hero, editorial, playlist, look, trending, study]
  },
  mutations: {
    setMobile(state, isMobile: boolean) {
      state.mobile = isMobile;
    },
    setHeroLoaded(state, isLoaded: boolean) {
      state.heroLoaded = isLoaded;
    },
    setNavOpen(state, isOpen: boolean) {
      state.navOpen = isOpen;
    }
  },
  actions: {}
});
