
import Vue, { CreateElement, VNode, RenderContext } from "vue";

export interface EaseFunction {
  (value: number): number;
}

const linear: EaseFunction = (v: number) => v;

export default Vue.extend({
  functional: true,
  render(h: CreateElement, context: RenderContext<Record<string, any>>): any {
    if (!context.props) {
      throw "<Percent /> requires :in, and optional :max, :min :ease properties";
    }

    const max = context.props.max || 0;
    const min = context.props.min || 0;
    const ease: EaseFunction = context.props.ease || linear;
    const diff = max - min;
    const percent = ease(context.props.in);
    const value = min + percent * diff;

    return context.scopedSlots.default({
      value,
      percent
    });
  }
});
