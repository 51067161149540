










































import Vue from "vue";
import { mapState } from "vuex";
import InView from "../layouts/sections/InView.vue";
import LazyImg from "@/components/fx/LazyImg.vue";
import VolumeLogoCombined from "@/components/VolumeLogoCombined.vue";
import BackgroundFill from "@/components/fx/BackgroundFill.vue";
import Percent from "@/components/fx/Percent.vue";
import { TimelineMax, Back, RoughEase, Power2 } from "gsap";
import { createThrottleListener } from "@/js/util/Listeners";
import { quadOut } from "../js/ease/easing";

// const plugins = [CSSPlugin, AttrPlugin ];
const heroImg = require("lqip-loader!../assets/v1/hero-video.jpg");

export default Vue.extend({
  components: {
    InView,
    Percent,
    VolumeLogoCombined,
    LazyImg,
    BackgroundFill
  },
  props: {
    bgColor: {
      type: String,
      default: "black"
    },
    color: {
      type: String,
      default: "#54FFFB"
    }
  },
  data(): Data {
    return {
      heroImg: heroImg,
      heroVid: require("@/assets/v1/hero-video.mp4"),
      loaded: false,
      heroVidLoaded: false,
      prog: 0,
      innerHeight: window.innerHeight,
      iOS: /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
    };
  },
  computed: {
    ...mapState(["mobile"]),
    hero(): string {
      return !this.loaded ? this.heroImg.preSrc : this.heroImg.src;
    },
    maxHeight(): any {
      // @ts-ignore
      // if (this.mobile) {
      //   return { maxHeight: innerHeight - 50 + "px" };
      // }
      // return { maxHeight: "calc(100vh - 50px)" };
      return {
        maxHeight:
          "calc(" + (this.iOS ? this.innerHeight + "px" : "100vh") + " - 50px)"
      };
    }
  },
  methods: {
    ease: quadOut,
    animateInVolume() {
      (this.$refs["logo"] as any).animateIn();
    },
    onScreenChange(inView: boolean) {
      const opts: any = this.$options;

      if (inView) {
        // this.$store.commit("setCurrentSection", {
        //   name: "news",
        //   color: "white"
        // });
        if (this.iOS) {
          // opts.disconnectResize = opts.onResize(() => this.onResize());
        }
      } else {
        opts.disconnectResize();
      }
    },
    onResize() {
      this.innerHeight = window.innerHeight;
      // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
      // let vh = window.innerHeight * 0.01;
      // Then we set the value in the --vh custom property to the root of the document
      // document.documentElement.style.setProperty("--vh", `${vh}px`);
    },
    onHeroLoad(): void {
      this.loaded = true;
      this.$store.commit("setHeroLoaded", true);
      this.$emit("loaded", true);
      (this.$options as any).tweenIn.play();
    },
    onHeroVidLoad(): void {
      this.heroVidLoaded = true;
      console.log("hero vid loaded");
    },
    onProgress(percent: number): void {
      // (this.$options as any).tl.progress(percent, false);
      this.prog = percent;
    }
  },
  mounted(): void {
    const opts: any = this.$options;
    opts.onResize = createThrottleListener("resize", window);
    opts.disconnectResize = () => {};
    this.onResize();

    const tweenIn: TimelineMax = new TimelineMax();
    // tweenIn.call(this.animateInVolume, undefined, this, "-=0.5");
    tweenIn.call(this.animateInVolume, undefined, this, "+=0.75");
    tweenIn.pause();
    opts.tweenIn = tweenIn;
  }
});
interface Data {
  heroImg: { preSrc: string; src: string };
  heroVid: string;
  loaded: boolean;
  heroVidLoaded: boolean;
  /** The progress of the amount in vue */
  prog: number;
  innerHeight: number;
  iOS: boolean;
}
