var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"main cf"},[(_vm.$slots.default)?_c('div',{staticClass:"visual",class:{ fullHeight: _vm.fullHeight }},[_vm._t("default")],2):_vm._e(),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"headline",class:{ right: _vm.side == 'right', left: _vm.side == 'left' }},[(!_vm.mobile && _vm.head && _vm.text)?_c('Parallax',{staticClass:"content",class:{
          right: _vm.side == 'right',
          left: _vm.side == 'left',
          'txt-dark': _vm.darkCaption
        },attrs:{"horizon":0.5,"distance":-5}},[_c('Caption',{attrs:{"head":_vm.head,"text":_vm.text,"darkText":_vm.darkCaption,"headSize":_vm.headSize,"url":_vm.url}})],1):_vm._e()],1),(_vm.$slots.sub)?_c('div',{staticClass:"sub-articles",class:{
        'start-left': _vm.subStartSide != 'right',
        'start-right': _vm.subStartSide == 'right'
      }},[_vm._t("sub")],2):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }